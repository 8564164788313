.modalP--content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    background-color: #fff;
    padding: 2rem;
    width: 30rem;
    height: 45rem;
}

.modalP--content-card {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}


.modalP--content a {
    font-size: 2rem;
    margin-top: 5rem;
    font-weight: bold; 
}

.modalP--content p {
    padding: 1.5rem 0;
    text-align: center;
    margin-top: 5rem; 
    
}

.modalP--content-card h2 {
    font-size: 1.6rem;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 2px;
    font-weight: lighter; 
    margin-top: 1rem;
}

.modalP--content-card .clickCall {
    margin-top: 5rem;
    padding: 0; 
}
