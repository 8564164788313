.card__container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-height: 54rem; 
}

.card {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    
    background-color: #f5f5f5;
    box-shadow: 7px 7px 5px -5px rgba(0, 0, 0, 0.2);
    padding: 3rem 3rem 5rem;
    margin: 1rem;
    width: 30rem;
    height: 44rem;
}

.contact__card-header{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}


.card a {
    text-decoration: none;
    color: #242424; 
}

.card h2 {
    font-size: 1.6rem;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 2px;
    font-weight: lighter; 
}

.card p {
    font-size: 1.6rem;
    padding: 1.5rem 0;
    text-align: center; 
}


@media (max-width:768px) {
    .card__container{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column; 
    }

    .card {
        margin: 0;
        margin-top: 4rem; 
    }

    .card:last-child {
        margin-top: 2rem;
        margin-bottom: 5rem; 
    }


}