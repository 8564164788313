.search {
    position: fixed;
    top: 0rem;
    z-index: 50;
    width: 100%;
    transform: translateY(-18rem);
    transition: var(--transition);
}

.searchActive {
    transform: translateY(0rem);
}

.search input {
    width: 100%;
    min-height: 18rem;
    border-top: 1px solid var(--color-acent);
    border-bottom: 1px solid var(--color-acent);
    border-left: none;
    border-right: none;
    font-size: 1.6rem;
    text-transform: none;
    text-align: center; 
}

.search input:focus {
    outline: none;
}

.search input:focus::placeholder {
    opacity: 0; 
}

.search .closeSearch {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 4rem;
    z-index: 51;
    font-size: 2.2rem; 
}

.search .closeSearch:hover {
    color: var(--color-acent);
    cursor: pointer;
}

@media (max-width: 1024px){
    .search input {
        min-height: 16rem; 
    }
}

@media (max-width: 768px){

    .search input {
        min-height: 15rem; 
    }

    .search input:focus {
        outline: none;
    }

    .search input:focus::placeholder {
        opacity: 0; 
    }

    .search .closeSearch {
        right: 3rem;
        top: 50%;
        transform: translateY(-50%); 
    }

}