.item-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    background: #fff;
    margin: 5rem 0;
}

.item-container-images {
    display: flex;
    position: relative;
}

.item-container-images-big {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    max-height: 44rem;
}

.item-container-images-big img {
    max-height: 44rem;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.2);
}

.item-container-images-big span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #daa52000;
    font-size: 2rem;
    text-transform: uppercase;
    transition: all .1s ease-out;
}

.item-container-images-big span:hover {
    background-color: rgba(0, 0, 0, 0.8);
    color: var(--color-acent);
}

.item-container-images-big .mainImage {
    max-height: 44rem;
    position: relative;
    box-shadow: var(--shadow);
}

.item-container-images-small {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 2.2rem;
}

.item-container-images-small img {
    height: 13rem;
    /* box-shadow: var(--shadow);  */
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.item-container-text {
    width: 30rem;
    height: 44rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.item-container-text .item-size p {
    color: var(--color-acent);
}

.item-container-text button {
    width: 100%;
}

.item-container-text h2 {
    font-size: 1.8rem;
    padding: 0;
    text-transform: none;
}

.item-container-text p {
    font-size: 1.6rem;
    font-weight: 300;
}

.item-container-text article {
    display: flex;
    align-items: flex-end;
    gap: 1.4rem;
}

.item-container-text article .quantity__change {
    display: flex;
    gap: 1rem;
}

.item-container-text article button {
    background-color: #242424;
    border: 1px solid #242424;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem;
    border-radius: 2px;
    transition: 0.2s linear background-color;
}

.item-container-text article button:hover {
    cursor: pointer;
    background-color: #ffffff;
    color: #242424;
}

.item-container-text .item-container-single {
    display: flex;
    flex-direction: column;
}


@media (max-width: 960px) {
    .item-container {
        padding: 0 2rem;
    }

    .item-container-text {
        width: 100%;
        margin: 4rem 0 0 0;
    }
}

@media (max-width: 768px) {

    .item-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0rem;
        padding: 3rem 0rem !important;
        width: 100% !important;
    }

    .item-container-images {
        display: flex;
        flex-direction: column;
    }

    .item-container-images-big img {
        width: 100%;
    }

    .item-container-images-small {
        flex-direction: row;
        align-items: center;
        margin-left: 0rem;
        margin-top: 1.6rem;
    }

    /* .item-container-images-big span{
        display: none;
    } */

    .item-container-text select {
        height: 4rem;
        margin-top: 1rem;
    }

    .item-container-text button {
        margin-top: 2rem;
    }

    .item-container-text {
        width: 100%;
        height: 40rem;
        margin: 4rem 0 0 0;
    }

    .tm-mobile {
        margin-top: 2rem;
    }
}