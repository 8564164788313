.bagModal {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100vw;
    height: 100vh;
    z-index: -1;
    opacity: 0;
    transition: var(--transition);
}

.bag {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    width: 39rem;
    height: 100%;
    background-color: #fff;
    z-index: 101;
    border-left: var(--border-accent);
    padding: 1.6rem;
    transform: translateX(100%);
    transition: var(--transition);
}

.bagActive {
    transform: translateX(0rem);
}

.bagModalActive {
    opacity: 1;
    z-index: 100;
    width: calc(100vw - 39rem);
}

.shopingBag {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
}

.bag button {
    margin-top: 1rem;
    width: 100% !important;
}

.shopingBag__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: var(--border);
    padding-bottom: 1rem;
}

.shopingBag__header h2 {
    font-size: 1.8rem;
    display: flex;
}

.shopingBag__header span,
.item__content span {
    font-size: 2.2rem;
    cursor: pointer;
}

.item {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1rem;
    /* margin-bottom: 1rem; */
    width: 100%;
    min-height: 21rem;
    border-bottom: var(--border)
}

.item .item__image {
    position: absolute;
    bottom: 1.8rem;
    right: 1.4rem;
    width: 8rem;
}

.item .item__image img {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    box-shadow: var(--shadow);
}

.item__content {
    width: 100%;
}

.item__content .item__description p {
    font-size: 1.6rem;
}

.item__content-quantity {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.item__content span {
    position: absolute;
    top: 1.4rem;
    right: 1.4rem;
}

.item__content label {
    color: var(--color-acent);
    font-weight: 300;
}


.shoping__bag-footer {
    padding-top: 1rem;
    border-top: var(--border);
    display: flex;
    justify-content: space-between;
}


@media(max-width: 540px) {
    .bag {
        width: 100%;
    }
}