.similar-items {
    margin-bottom: 6rem;
}

.similar-items h2 {
    border-bottom: var(--border);
    margin-bottom: 3rem;
}

.similar-items-category {
    display: flex;
    gap: 9rem;
    justify-content: center;
}

.similar-items-category-cont {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border: var(--border);
    padding: 1rem;
    width: 22rem;
    min-height: 38rem;
    transition: all 0.2s;
}

.similar-items-category-cont button {
    min-width: 100%;
}

.similar-items-category-cont:hover {
    visibility: visible;
    box-shadow: var(--shadow);
}

.similar-items-category img {
    width: 100%;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.2);
}

.similar-items-category h3 {
    text-align: center;
}

@media (max-width: 960px) {

    .similar-items-category {
        gap: 2rem;
    }

    .similar-items-category-cont:nth-child(4) {
        display: none;
    }
}

@media (max-width: 768px) {
    .similar-items {
        margin-top: 4rem;
        padding: 0rem;
    }

    .similar-items-category {
        justify-content: center;
        gap: 2rem;
        margin: 0;
    }

    .similar-items-category-cont:nth-child(4) {
        display: none;
    }

}

@media (max-width: 540px) {

    .similar-items-category-cont:nth-child(3) {
        display: none;
    }

    .similar-items-category-cont:nth-child(4) {
        display: none;
    }

    .similar-items-category {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        gap: 4rem;
    }
}
