.modal {
    /* transition: var(--transition); */
    background-color: rgba(0, 0, 0, 0.7); 

    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    visibility: none;
    opacity: 0;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    /* display: none; */
    z-index: -10;
}

.modalActive {
    visibility: visible;
    opacity: 1;

    z-index: 30;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
}

.modal span {
    position: absolute;
    top: 4rem;
    right: 5rem;
    color: #fff;
    cursor: pointer;
}

.modal span:hover {
    color: var(--color-acent);
}


@media (max-width: 768px){

    .modal span {
        top: 2rem;
        right: 2rem;
    }
    
}