.error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    width: 100%;
    height: 40rem;
    margin: 0 auto;
}

.error img {
    height: 7rem;
    margin-bottom: 2rem;
}

.error h1 {
    font-weight: 400;
}

.error h2 {
    text-transform: none;
    font-size: 2.3rem;
    text-align: center;
}

.error .error-link {
    text-decoration: underline;
    color: var(--color-acent);
    cursor: pointer;
    margin-top: 1rem;
    font-size: 1.8rem;
}

.error .error-link:hover {
    color: gray;
}