nav {
    height: 4rem;
    width: 100%;
    background: #f3f3f3;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    font-size: 1.4rem;
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar__left {
    width: 60rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 4rem;
}

.navbar__left .lang-menu {
    width: 8rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: .3rem;
    background-color: #ffffff;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
    padding: 0 .5rem;
    width: 6rem;
    height: 3rem;
}

.navbar__left .pages-menu {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.navbar__left .pages-menu li {
    margin-right: 2rem;
}

.navbar__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 60rem;
    height: 4rem;
    position: relative;
}

.navbar__right li {
    margin-left: 2rem;
}

.navbar__right .navbar__right-user {
    display: flex;
}

.navbar__right .navbar__right-user-name {
    text-transform: capitalize;
    color: var(--color-acent);
    margin-left: 1rem;
}

.mobile__menu {
    display: none;
}

.bag__full {
    position: absolute;
    top: .8rem;
    right: -.8rem;
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bag__icons-container {
    position: relative;
}

.bag__full label {
    position: relative;
    color: white;
    z-index: 51;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50rem;

    font-size: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .9rem;
    font-weight: 400;
    cursor: pointer;
}


span {
    display: flex;
    align-items: center;
}

span:hover {
    color: #daa520;
}

.lang-active {
    color: hotpink;
}

@media (max-width:768px) {

    .navbar__left,
    .login {
        display: none;
    }

    .mobile__menu {
        display: flex;
    }

    .mobile-registration {
        display: none;
    }

    /* .navbar__right-user-name {
        position: absolute;
        left: 0;
    } */

    .navbar__right .navbar__right-user {
        position: absolute;
        left: 0;
    }

}