/* @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800&family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800&display=swap'); */

@font-face {
  font-family: "Nunito";   /*Can be any text*/
  src: local("Nunito-Light"),
    url("./fonts/static/Nunito-Light.ttf") format("truetype");
}




:root {
  --shadow: 7px 7px 5px -5px rgba(0, 0, 0, 0.2);
  --transition: all 0.2s ease-out;
  --color-acent: #daa520;
  --color-font: #242424;
  --color-font-gray: #979797;
  --color-border: #8888;
  --border: 1px solid #d8d8d8f8;
  --border-accent: 1px solid #daa520;
  --color-border: #8888;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  overflow-x: hidden;
  font-size: 62.5%;
}

body {
  font-family: 'Nunito', sans-serif;
  font-weight: light;
  text-decoration: none;
  font-size: 1.6rem;
  color: var(--color-font);
  background-color: #ffffff;
  line-height: 1.6;
  letter-spacing: .05rem;
  -webkit-font-smoothing: subpixel-antialiased;
  outline: 0;
}

:-moz-focusring {
  outline: none;
}

.container {
  width: 85%;
  margin: 0 auto;
}

.card-custom {
  opacity: .8;
}

li {
  list-style: none;
  text-transform: uppercase;
  outline: 0;
}

Link {
  outline: 0;
}

a {
  text-decoration: none;
  color: #242424;
}

a:hover {
  color: var(--color-acent);
  cursor: pointer;
}

h1 {
  font-size: 2.2rem;
  margin: 0;
  font-weight: regular;
}

h2 {
  text-decoration: none;
  font-size: 2rem;
  font-weight: 300;
  text-transform: uppercase;
}

h3 {
  font-size: 1.4rem;
  font-weight: 300;
  text-transform: uppercase;
}

span{
  font-weight: 300;
}

p {
  font-weight: 300;
  font-size: 1.8rem;
}

label {
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--color-font-gray);
}

.accent {
  color: var(--color-acent);
  font-weight: 500;
}

.current {
  font-weight: 500;
}

.Toastify__toast-container {
  min-width: 37rem;
}

.Toastify__toast-body {
  min-width: 33rem;
}



/* ======================================== */
/* Input polje u ovoj komponenti */
input[type=number] {
  width: 4.5rem;
  height: 3rem;
  padding: 0;
  font-size: 1.6rem;
  text-align: center;
}

input[type=number]:focus {
  outline: none;
}

input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
  margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: textfield;
  margin: 0;
}

/* ======================================== */

/* ================================================== */
/* Start of Custom Utiliy Classes */

.fs-20{
  font-size: 2rem;
}

.ml-1 {
  margin-left: 1rem;
}

.mr-1 {
  margin-right: 1rem;
}

.ml-2 {
  margin-left: 2rem;
}

.ml-3 {
  margin-left: 3rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-7 {
  margin-top: 7rem;
}

.mt-8 {
  margin-top: 8rem;
}

.mt-10 {
  margin-top: 10rem;
}

.opacity-6 {
  opacity: .6;
}

.bgc-main {
  background-color: var(--primary-color);
}

/* Start of Border */
.brd-faid {
  /* border: 1px solid var(--font-color-faid); */
  border: var(--border);
  border-radius: 3px;
}

.border-shadow {
  box-shadow: var(--border-shadow);
  -webkit-box-shadow: var(--border-shadow);
}

/* End of Border */

.tc-main {
  color: var(--font-color-main);
}

.tc-faid {
  color: var(--font-color-faid);
}

.tc-accent {
  color: var(--accent-color);
}

.tc-danger-muted {
  color: rgba(255, 82, 82, 0.6);
}

.tc-info-muted {
  color: rgba(45, 178, 255, 0.6);
}

.tc-success-muted {
  color: rgba(0, 170, 113, 0.6);
}

.theme-c-1 {
  color: var(--theme-color-11);
}

.theme-c-2 {
  color: var(--theme-color-12);
}

.theme-c-3 {
  color: var(--theme-color-13);
}

.theme-c-4 {
  color: var(--theme-color-14);
}

.theme-c-5 {
  color: var(--theme-color-15);
}

.danger-cust {
  color: var(--danger-color);
}

.text-bold{
  font-weight: 500;
}


/* End of Custom Utiliy Classes */

/* Buttons */
.custBtn-primary {
  width: 100%;
  height: 3.5rem;
  background-color: var(--accent-color);
  /* background-color: var(--color-info); */
  color: var(--white-color);
}

.custBtn-primary:hover {
  background-color: var(--hover-accent-color);
  /* background-color: var(--color-info-hover); */
  color: var(--white-color);
}

/* Buttons */

/* Links */
.links {
  color: var(--accent-color);
  cursor: pointer;
}


@media (min-width: 1920px) {

  .container-xxl {
    width: 192rem;
    margin: 0 auto;
  }
}

@media (max-width: 1920px) {

  .container {
    width: 90%;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {

  .container {
    width: 90%;
    margin: 0 auto;
  }
}

