.my{
    margin: 8rem 0;
}

section {
    width: 100%;
}

.showcase {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  height: 60rem;
}

.reverse {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  height: 60rem;
}

.text__content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 50rem;
    /* height: 100%; */
    text-align: center;
    padding-top: 0px;
    padding-bottom: 0px;
    /* margin-top: 20px; */
}

.logo-img{
    position: absolute;
    top:0;
    left: 0;
    height: 60rem;
    width: 100%;
    z-index: 2;
    display: flex;
    justify-content: center;
}

.text__content-desc{
    position: relative;
    z-index: 3;
    height: 60rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.showcase .text__content-desc p, 
.reverse .text__content-desc p {
  padding: 2rem 0; 
}

.showcase .img,
.reverse .img {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 48rem;
    /* height: 0rem; */
    /* box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1); */
    box-shadow: 7px 7px 5px -5px rgba(0, 0, 0, 0.2);
}

.showcase .img img, 
.reverse .img img {
  width: 48rem;
  /* height: 60rem; */
}

@media (min-width: 1980px) {
    .my {
        margin: 8rem 0rem;
        padding: 0 10rem;
    }
}

@media (max-width: 1200px) {
    .showcase .img img,
    .reverse .img img {
        width: 48rem;
        /* height: 60rem; */
    }

    .showcase .text__content,
    .reverse .text__content {
        /* border-top: solid 1px rgba(192, 192, 192, 0.4);  */
        border-top: none; 
        width: 100%;
    }

    .logo-img{
        display: none;
    }

    .showcase,
    .reverse {
        height: 82rem;
        border-top: solid 1px rgba(192, 192, 192, 0.4); 
        margin: 6rem 0rem;
    }

    .text__content-desc{
        height: 22rem;
    }
}

@media (max-width: 768px) {

    .my {
        margin: 5rem 0;
    }

    /* .showcase,
    .reverse {
        height: 72rem;
        padding: 3rem 0;
        background-color: aqua;
        border-top: solid 1px rgba(192, 192, 192, 0.4);
    } */

    .showcase .text__content,
    .reverse .text__content {
        border: none;
        width: 100%;
        padding-top: 3rem;
        /* background-color: bisque; */
        /* height: 36rem; */
    }

    /* .text__content-desc{
        padding-top: 5rem;
        height: 10rem;
    } */
    
    .showcase .text__content-desc p, 
    .reverse .text__content-desc p {
      padding-bottom: 3rem; 
      padding-top: 3rem; 
      /* padding: 3rem 0; */
    }

    .logo-img{
        display: none;
    }

    .showcase .img,
    .reverse .img {
        width: 100%;
        /* margin-top: 0rem; */
    }

    .showcase .img img,
    .reverse .img img {
        width: 100%;
    }

    /* .showcase .text__content-desc h2,
    .reverse .text__content-desc h2 {
        padding-top: 2rem; 
    } */
}