.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0a0a0a;
    padding: 0 2rem;
}

.footer .footer-main {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 5rem;
}

.visit-us {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10rem;
    letter-spacing: 1px;
    text-align: center;
}

.visit-us h2 {
    font-size: 2rem;
    text-transform: uppercase;
    color: #f5f5f5;
    width: 100%;
}

.footer .footer-main .socials {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.footer .footer-main .socials--content {
    padding-bottom: 2.2rem;
}

.footer .footer-main .socials--content a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #f5f5f5;
}

.footer .footer-main .socials--content a p {
    font-size: 2.4rem;
    padding-left: 1.4rem;
    padding-bottom: .5rem;
}

.footer .footer-main .socials--content a:hover {
    color: grey;
}

.footer .footer-main .privacy ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
}

.footer .footer-main .privacy ul h2 {
    font-size: 1.5rem;
    text-transform: uppercase;
    color: #f5f5f5;
    padding: 0 0 1rem 0;
    letter-spacing: 2px;
}

.footer .footer-main .privacy li {
    list-style-type: none;
    padding-bottom: 1rem;
}

.footer .footer-main .privacy li a {
    text-decoration: none;
    color: grey;
    font-size: 1.5rem;
    text-transform: capitalize;
}

.footer .footer-main .privacy li a:hover {
    color: #f5f5f5;
}

.footer .pay {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    height: 13rem;
}

.footer .pay--logo {
    text-align: center;
    padding: 1rem;
    transform: scale(1.4);
}

.footer .pay--logo img {
    padding: 1rem;
}


.shopingrights .rights--txt p {
    font-size: 1.2rem;
    /* padding: 1rem; */
    margin-bottom: 2rem;
    color: #808080;
    text-align: center;
}

.footer .copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 40%;
    border-top: 1px solid grey;
}

.footer .copyright .SC {
    padding: .7rem 0 0 0;
    font-size: 1.3rem;
    text-align: center;
    color: grey;
}

.footer .copyright a {
    color: #71b1a366;
    padding: 0 0 .7rem 0;
    font-size: 1.3rem;
    width: 60%;
    text-decoration: none;
    text-align: center;
}


@media (min-width: 1920px) {
    .footer .footer-main {
        display: flex;
        width: 192rem;
    }
}

@media (max-width: 1920px) {
    .footer .footer-main {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
    }
}

@media (max-width: 1024px) {
    .footer .footer-main {
        display: flex;
        justify-content: space-evenly;
        margin: 0 auto;
        min-height: 60rem;
        margin-bottom: 0rem;
    }

    footer .footer-main .socials--content:first-child {
        padding-top: 4rem;
    }

    footer .footer-main .socials--content:last-child {
        padding-bottom: 4rem;
    }

    footer .footer-main .socials {
        width: 40%;
        height: 3rem;
    }

    footer .footer-main .map {
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    footer .privacy {
        height: 8em;
        margin-top: 5rem;
    }

    footer .privacy ul {
        justify-content: flex-start;
        align-items: center;
    }

    footer .pay {
        display: flex;
        flex-direction: column;
        min-height: 10rem;
    }

    footer .pay--logo {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        justify-items: center;
        padding: 0;
    }

    footer .shopingrights .rights--txt p {
        text-align: center;
        /* padding: 0 4rem 0 4rem; */
    }

    footer .copyright {
        align-items: center;
        justify-content: center;
    }

    footer .copyright p {
        min-width: 70%;
    }

    footer .copyright a {
        padding: 2rem;
    }
}

@media (max-width: 768px) {
    footer {
        min-width: 100%;
    }

    footer .footer-main {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 80rem !important;
    }

    footer .footer-main .socials {
        width: 100%;
        height: 17rem;
        margin-bottom: 2rem;
        justify-content: center;
        margin: 0;
        align-items: center;
    }

    .footer .footer-main .socials--content {
        padding-bottom: 2.2rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    .footer .footer-main .socials--content a {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    footer .footer-main .socials--content:first-child {
        padding-top: 4rem;
    }

    footer .footer-main .socials--content:last-child {
        padding-bottom: 4rem;
    }

    footer .footer-main .privacy {
        width: 100%;
        margin-top: 1rem;
    }

    footer .footer-main .privacy ul {
        justify-content: center;
    }

    footer .pay {
        display: flex;
        flex-direction: column;
        min-height: 20rem;
    }

    footer .pay--logo {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items: center;
        padding: 0;
    }

    footer .shopingrights .rights--txt p {
        margin-bottom: 4rem;
        text-align: justify;
        width: 100%;
    }

    footer .copyright {
        align-items: center;
        justify-content: center;
        min-width: 100%;
    }

    footer .copyright a {
        padding-bottom: 1rem;
    }

}