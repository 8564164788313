.proizvodi__container {
    width: 100%;
    display: grid;
    margin: 3rem 0;
    align-items: center;
    justify-content: center;
    min-height: 48rem;
    grid-template-columns: repeat(auto-fit, 36rem);
    grid-gap: 3rem;
    /* background-color: aquamarine; */
}

.proizvodi__container .proizvod {
    background-color: #fff;
    min-height: 50rem;
    width: 33rem;
    transition: var(--transition);
    border: 1px solid var(--color-border);
    box-shadow: var(--shadow);
    position: relative;
}

.proizvodi__container .proizvod__image {
    height: 80%;
    /* position: relative; */
}

.proizvodi__container .proizvod__image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: var(--transition);
}

.proizvodi__container .proizvod__image img:hover {
    opacity: 0;
    cursor: pointer;
}

.proizvodi__container .proizvod__opis {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 15%;
    padding: 1rem;
    position: absolute;
    bottom: 0;
    left: 0;
}

.proizvodi__container .proizvod__opis-txt {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    height: 100%;
}

.proizvodi__container .proizvod__opis-txt h1 {
    font-size: 1.8rem;
    font-weight: 400;
    margin: 0px;
    padding: 0px;
}

.proizvodi__container .proizvod__opis-txt h2 {
    font-size: 1.6rem;
    margin: 0px;
    padding: 0px;
    font-weight: 300;
    text-transform: lowercase;
}

.proizvodi__container .proizvod__opis-bag{
    position: absolute;
    bottom: 1rem;
    right: 1rem;
}

.proizvodi__container .proizvod__opis-bag span:hover {
    cursor: pointer;
    color: var(--color-acent);
}

@media (min-width: 1920px) {

    .proizvodi-container-item {
        grid-template-columns: repeat(4, 34rem);
    }
}

@media (max-width: 1200px) {

    .proizvodi__container {
        grid-template-columns: repeat(auto-fit, 32rem);
    }

    .proizvodi__container .proizvod {
        width: 32rem;
    }
}