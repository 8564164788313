.nav__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem 0;
    background-color: #ffffff;
    position: fixed;
    top: 4rem;
    width: 100%;
    z-index: 10;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}

.logo {
    display: flex;
    align-items: center;
    align-items: center;
    outline: none;
    border: none;
    height: 7rem;
    transition: height .2s linear;
}

.logo img {
    height: 6rem;
    cursor: pointer;
    outline: none;
    border: none;
    transition: all .2s linear;
}

.menus {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 3rem;
}

.menus ul {
    display: flex;
}

.menus ul li {
    margin: 0 1.4rem;
    cursor: pointer;
}


/* Hiding logo and Logo container on scroll*/
/* .moveLogo {
    transform: translateY(-5rem);
} */

.moveLogo.logo {
    transform: translateY(-2.7rem) !important;
    height: 0rem !important;
    transition: height .2s linear;
}

.moveLogo.logo img {
    height: 3.2rem;
}




@media (max-width: 768px) {
    .menus {
        display: none;
    }

    .moveLogo.logo {
        transform: translateY(0rem) !important;
        height: 7rem !important;
        transition: height .2s linear;
    }
    
    .moveLogo.logo img {
        height: 6rem;
    }
}