.devider {
    width: 100%;
    height: 15.5rem !important;
    background-color: #fff !important;
    /* background-color: rgb(113, 163, 228) !important; */
}

/* .nav__logo.hideLogo .devider {
    min-height: 5rem ;
  } */

  /* .moveLogo.devider{
    height: 10rem !important;
  } */

@media (max-width: 768px){
    .devider {
        height: 12rem !important;
    }
}