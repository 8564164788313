.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  /* z-index: 10000; */
  background-color: transparent;
  background-color: #fff;
}

.loader {
  background: transparent;
  overflow: hidden;
  position: relative;
  height: 6rem;
  width: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader p {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
}

.loader span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  width: 5rem;
  border: 4px solid #45474b83;
  border-top-color: #daa520;
  border-radius: 50%;
  animation: rotation 1.2s infinite linear;
}

@keyframes rotation {
  100% {
    transform: rotate(360deg);
  }
}