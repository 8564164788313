.submenu {
    border-top: 1px solid #daa520;
    border-bottom: 1px solid #daa520;
    height: 26rem;
    padding: 1rem;
    position: fixed;
    top: -4.3rem;
    left: 0;
    z-index: 8;
    background-color: #fff;
    transform: translateY(-100%);
    transition: transform .2s ease-out;
}


.submenu__container {
    width: 100%;
    height: 24rem;
    display: flex;
}

.submenu__container span {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 2rem;
    right: 3rem;
    width: 3rem;
    height: 3rem;
}

span:hover {
    color: var(--color-acent);
}

.submenu__image {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 20rem;
    height: 24rem;
}

.submenu__image img {
    width: 18rem;
    /* height: 24rem; */
}

.submenu__subcategories {
    width: 20rem;
    height: 24rem;
    margin-left: 6rem;
}

.submenu__subcategories ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    height: 22rem;
}

.submenu__subcategories li {
    width: 100%;
    height: 2rem;
    text-transform: capitalize;
    margin-bottom: 1.4rem;
    cursor: pointer;
    font-size: 1.8rem;
}

.submenu__subcategories li:hover {
    color: var(--color-acent);
}



/* Open sub menu options */
.submenuActive {
    transform: translateY(20rem);
}

/* .moveLogo.submenuActive {
    transform: translateY(13rem) !important;
} */

.submenuActiveSmall {
    transform: translateY(13rem);
}


@media (max-width: 768px) {

    .submenu {
        display: none;
    }
}