.items-description {
    margin-bottom: 4rem;
    min-height: 40rem;
    overflow-x: hidden;
}

.items-description h2 {
    border-bottom: var(--border);
    margin-bottom: 3rem;
}


.items-description-cont {
    display: flex;
    margin: 0 auto;
    position: relative;
}

.items-description-cont-1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 5rem;
}

.items-description-cont-1-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    width: 100%;
}


.items-description-cont-1-heading p {
    color: var(--color-acent);
    cursor: pointer;
    text-decoration: underline;
}

.items-description-cont-1-single {
    padding-bottom: 1.2rem;
    text-align: start;
}

.items-description-cont-1-content {
    width: 100%;
}

.items-description-cont-1-content table {
    border: 1px solid #d8d8d8f8;
    border-collapse: collapse;
    width: 100%;
}

.items-description-cont-1-content table th {
    padding: .4rem;
}

.items-description-cont-1-content table td {
    border: var(--border);
    width: 2rem;
    padding: .4rem;
}

.items-description-cont-1-content .wash {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* gap: .3rem; */
    width: 100%;
    padding-top: .6rem;
}

.items-description-cont-1-content .wash img {
    height: 6rem;
}


@media (max-width: 960px) {

    .items-description-cont-1 {
        padding-left: 0;
        padding-right: 1rem;
    }

    .items-description-cont-1:last-child {
        padding-right: 0;
        padding-left: 1rem;
    }

    .items-description-cont {
        flex-direction: column;
    }
}


@media (max-width: 768px) {
    .items-description {
        padding: 0rem !important;
        margin: rem 0 !important;
    }

    .items-description-cont {
        flex-direction: column;
    }

    .items-description-cont-1 {
        padding: 0 !important;
        width: 100%;
    }

    .items-description-cont-1-single {
        padding-bottom: 1rem;
        text-align: start;
    }

    .items-description-cont-1-heading {
        margin: 0rem 0 2rem;
    }

}